import { BoxWithShadow } from 'components/blocks/BoxWithShadow';
import React, { useEffect } from 'react';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import styles from './styles.module.scss';

const PARTNER_ID = '3e08';

const WeSkiWidget = ({ destinationId, withTopCopy }: { destinationId: string, withTopCopy?:boolean }) => {
  const { t } = useOTSTranslation('common');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.weski.com';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <BoxWithShadow>
      {withTopCopy
      && (
      <p className={styles.copyWrapper}>
        {t('resort.bookTrip.copy')}
      </p>
      )}
      <div
        id="weski-widget"
        data-partner={PARTNER_ID}
        data-destination-id={destinationId}
      />
    </BoxWithShadow>
  );
};

export default WeSkiWidget;
