import { BlockContent } from 'components/blocks/BlockContent';
import { BoxWithShadow } from 'components/blocks/BoxWithShadow';
import React from 'react';
import WeSki from './WeSkiWidget';

const GlobalTemplateWeSkiWidget = () => {
  if (process.env.NEXT_PUBLIC_LANG !== 'en-GB') return null;
  return (
    <BoxWithShadow>
      <BlockContent>
        <WeSki destinationId="resort-1" />
      </BlockContent>
    </BoxWithShadow>
  );
};

export default GlobalTemplateWeSkiWidget;
