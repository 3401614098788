import { LdJsonOrganization, LdJsonSearchAction } from 'components/LdJson';
import { AdUnitContext } from 'googlead/components/AdUnitContext';
import { TopAd } from 'googlead/components/TopAd';
import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { HeadMeta } from 'components/common/HeadMeta';
import { HeaderNav } from 'components/common/HeaderNav';
import { Layout } from 'components/resort/Layout/Layout';
import { Main } from 'components/resort/Layout/Main';
import { BottomMountains } from 'components/blocks/BottomMountains';
import { Footer } from 'components/blocks/Footer';
import { RouteAndAnalyticsProps } from 'page-components/pathTypes';
import React, { useCallback, useState } from 'react';
import { HomeHero } from 'components/home/HomeHero';
import {
  fetchBackgroundImages, fetchLastNews, fetchPopularSearch, fetchResortTopRating, fetchSnowWebcams,
} from 'api/home/static';
import {
  IBackgroundImages, INews, IPopularSearch, IResortTopRated, ISnowWebcam, ResortTopRated,
} from 'api/home/types';
import { SnowWebcams } from 'components/home/SnowWebcams';
import { BestResorts } from 'components/home/BestResorts';
import { NewsList } from 'components/home/NewsList';
import { Routes } from 'routes';
import { useRouter } from 'next/router';
import { getPortalOptions } from 'util/getPortalConfig';
import { isMainLocale } from 'util/i18nFlags';
import IntegrityError from 'page-components/400';
import { wrapServerSideProps, wrapStaticProps } from 'util/wrapProps';
import { propsWithI18NSSR } from 'util/propsWithI18n';
import GlobalTemplateWeSkiWidget from 'components/common/PlanYourTrip/WeSki/GlobalTemplateWeSkiWidget';
import { HeaderNavCurLink } from 'components/common/HeaderNav/MobileSummerHeader';

export type HomePageProps = {
  popularSearch: IPopularSearch,
  backgroundImage: IBackgroundImages,
  snowWebcams: Array<ISnowWebcam>,
  resortTopRatingOverall: Array<IResortTopRated>,
  lastNews: Array<INews> | null,
  type?: 'global' | undefined,
} & RouteAndAnalyticsProps;

export const DEBUG_TAG = 'home-page';
export const TEMPLATE_ID = '1.0';
export const TEMPLATE_NAME = 'Home Page';

export default function HomePage({
  popularSearch,
  backgroundImage,
  snowWebcams,
  resortTopRatingOverall,
  lastNews,
  type,
}: HomePageProps) {
  const { hasNews } = getPortalOptions();

  const { t } = useOTSTranslation('common');
  const { t: seoT } = useOTSTranslation('seo');

  if (!popularSearch || !backgroundImage) {
    return <IntegrityError />;
  }

  const [searchQuery, setSearchQuery] = useState<string | string[] | undefined>();
  const router = useRouter();

  const clearSearchQuery = useCallback(async () => {
    if (global?.location?.search) {
      await router.replace(global?.location?.pathname);
    }

    setSearchQuery(undefined);
  }, [searchQuery]);

  const { query } = router;

  if (!searchQuery && query.q) {
    setSearchQuery(query.q);
  }

  return (
    <AdUnitContext
      adType="home"
      adTKey="other home"
    >
      <HeadMeta
        title={seoT('Other.home.title')}
        description={seoT('Other.home.description')}
        image={backgroundImage.image}
      />
      <HeaderNav current={HeaderNavCurLink.MAIN} />
      <TopAd id="home" />
      <HomeHero
        backgroundImage={backgroundImage}
        popularSearch={popularSearch}
        searchQuery={searchQuery}
        clearSearchQuery={clearSearchQuery}
      />
      <div className="container-xl content-container">
        <Layout className="layout-container-noback">
          <Main>
            {
                !!(snowWebcams && snowWebcams.length)
                && (
                  <SnowWebcams
                    headerScope="h1"
                    isFresh
                    showSnowReport
                    snowWebcams={snowWebcams}
                    imageLink={Routes.resortSnowReport}
                    titleLink={Routes.resortSnowReport}
                    header={t('homePage.h1Title')}
                  />
                )
              }
            {lastNews && hasNews
                && (
                  <NewsList
                    areNewsFresh
                    title={t('homePage.latestNews')}
                    news={lastNews}
                  />
                )}

            <GlobalTemplateWeSkiWidget />
            {
                isMainLocale()
                && resortTopRatingOverall
                && (
                  <BestResorts
                    pageType={type}
                    resortTopRatingOverall={resortTopRatingOverall}
                  />
                )
              }
          </Main>
          <LdJsonSearchAction />
          <LdJsonOrganization />
        </Layout>
      </div>
      <BottomMountains />
      <Footer />
    </AdUnitContext>
  );
}

export const fetchProps = async (): Promise<HomePageProps> => {
  const popularSearch = await fetchPopularSearch();
  const backgroundImage = await fetchBackgroundImages();
  const snowWebcams = await fetchSnowWebcams(6);
  const resortTopRatingOverall = await fetchResortTopRating(ResortTopRated.overall);
  const { hasNews } = getPortalOptions();
  const lastNews = hasNews ? (await fetchLastNews()) : null;
  return {
    type: 'global',
    route: 'index',
    popularSearch,
    backgroundImage,
    snowWebcams,
    resortTopRatingOverall,
    lastNews,
    templateName: TEMPLATE_NAME,
    templateId: TEMPLATE_ID,
    prefetch: false,
  };
};

// This function gets called at ISR build time

export const getStaticPropsDeprecated = wrapStaticProps(fetchProps);

export const getServerSideProps = propsWithI18NSSR(wrapServerSideProps(fetchProps));
